@import '../../styles/_mixins';

.section{
    min-height: calc(100vh - 100px);
    width: 99vw;
    position: relative;
    display: flex;
    flex-direction: column;

    .title{
        font-size: 3rem;
        text-align: left;
        margin: 3% 6%;
        margin-top: 10%;

        @include respond-below(xs){
            margin: 3% 6%;
            font-size: 2.5rem;
        }
        @include respond-below(xxs){
            font-size: 2.1rem;
            letter-spacing: 0;
        }
    }


    .row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        margin: 0 auto;
        flex: 1;

        @include respond-below(sm){
            width: 96%;
        }
        @include respond-below(xs){
            flex-direction: column;
        }

        .col{
            display: flex;
            width: 30%;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include respond-below(xs){
                width: 85%;
                margin-bottom: 10px;
            }
            @include respond-below(xxs){
                margin-bottom: 20px;
            }

            .circle{
                border-radius: 50%;
                // border: 2px solid grey;
                width: 150px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

                .logo{
                    width: 70px;
                    height: 70px;
                }
            }
            .techStack{
                font-size: 1.5rem;
                font-weight: 600;

                @include respond-below(xs){
                    font-size: 1.5rem;
                    margin : 10px 0;
                }
            }
            .description{
                width: 90%;
                
                @include respond-below(sm){
                    width: 95%;
                    font-size: 1rem;
                }
                @include respond-below(xxs){
                    width: 100%;
                    margin: 5px 0;
                }
            }
        }

    }
    .footer{
        padding: 30px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include respond-below(sm){
            width: 95%;
            margin: 0 auto;
            display: block;
        }
        @include respond-below(xs){
            font-size: 0.8rem;
            width: 80%;
           
        }

        .emoji{
            width: 20px;
        }
    }
}