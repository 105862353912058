@import '../../styles/_mixins';

.section{
    width: 98vw;
    // min-height: 96vh;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .title{
        font-size: 3rem;
        text-align: left;
        margin: 3% 6%;
        width: 40%;
        margin-bottom: 0;

        @include respond-below(xs){
            margin: 3% 6%;
            font-size: 2.5rem;
        }
        @include respond-below(xxs){
            font-size: 2.1rem;
        }
    }

    .grids{
        width: 60%;
        display: flex;
        height: 500px;
        flex-direction: row;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: 5%;
        margin-bottom: 50px;

        @include respond-below(md){
            height: 400px;
            width: 70%;
        }
        @include respond-below(sm){
            height: 300px;
            width: 80%;
        }
        @include respond-below(xs){
            height: 300px;
            width: 90%;
            margin: 50px auto;
        }
        @include respond-below(xxs){
            margin-top: 50px;
            flex-direction: column ;
            height: auto;
            width: 70%;
            margin: 0 auto;
        }
        

        .grid1{
            width: 170px;
            filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));
            align-self: flex-start;

            @include respond-below(xxs){
                width: 100px;
            }
            .image{
                width: 100%;
            }
        }
        .grid2{
            filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5));
            width: 170px;
            align-self: flex-end;

            @include respond-below(xxs){
                width: 100px;
            }
            .image{
                width: 100%;
            }
        }
    }

    .footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include respond-below(sm){
            width: 95%;
            margin: 0 auto;
            display: block;
        }
        @include respond-below(xs){
            font-size: 0.8rem;
            width: 80%;
           
        }

        .emoji{
            width: 20px;
        }
    }
}