@import '../../styles/mixins';

.section{
   height: calc(100vh - 100px);
    width: 99vw;
    position: relative;
    display: flex;
    flex-direction: column;
    // margin: 0 auto;
    
    @include respond-below(xs){
        width: 98vw;
    }

    .title{
        font-size: 3rem;
        text-align: left;
        margin: 3% 6%;

        @include respond-below(xs){
            font-size: 2.5rem;
        }
    }


    .row{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 90%;
        margin: 0 auto;
        flex: 1;
        padding: 3% 0;
        
        @include respond-below(xs){
            flex-direction: column;
        }

        .col{
            display: flex;
            width: 30%;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            @include respond-below(sm){
                width: 80%;
            }
            @include respond-below(xs){
                margin-bottom: 25px;
            }

            .circle{
                border-radius: 50%;
                width: 230px;
                height: 230px;
                display: flex;
                justify-content: center;
                align-items: center;
                filter : drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));

                @include respond-below(sm){
                    width: 150px;
                    height: 150px;
                }

                .logo{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
            .techStack{
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0;
                
                @include respond-below(sm){
                   font-size: 1.2rem;
                   font-weight: 500;
                }
            }
            .description{
                width: 90%;
                margin: 0;
            }
        }

    }
    .footer{
        padding:  0;
        .emoji{
            width: 20px;
        }
    }
    .footerBar{
        width: 100%;
        padding: 2px 3px;
        background-color: black;
        color: white;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0;

        @include respond-below(sm){
        }

        .emoji{
            width: 20px;
        }
    }
}