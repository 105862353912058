@import '../../styles/_mixins';

.Section{
        width: 98vw;
        min-height: 100vh;
        margin: 0 auto;

        @include respond-below(xs){
            width: 97vw;
            overflow: hidden;
        }

    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 87%;
        margin: 0 auto;
        padding-top: 20vh;

        @include respond-below(sm){
            width: 90%;
        }
        @include respond-below(xs){
            flex-direction : column;
            padding-top: 10px;
        }

        .col{
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            
            @include respond-below(xs){
                text-align : center;
                width : 100%;
            }
            .title{
                font-size: 5rem;
                font-weight: 600;
                letter-spacing: 1px;
                margin-top: 15%;
                margin-bottom: 5px;
                text-align: start;

                @include respond-below(sm){
                   font-size: 2.95rem;
                }
                @include respond-below(xs){
                    text-align : center;
                    width : 100%;
                }
                @include respond-below(xxs){
                    font-size: 4rem;
                    margin-top: 10px;
                }
            }
            .subTitle{
                font-size: 1.7rem;
                width: 90%;
                text-align: left;

                @include respond-below(sm){
                    font-size: 1.6rem;
                    width: 95%;
                }
                @include respond-below(xs){
                    text-align: center;
                    width: 100%;
                    font-size: 1.5rem;
                }
            }
            .button{
                text-decoration: none;
                width: 200px;
                display: block;
                @include respond-below(xs){
                    display: none;
                }
        
                .buttonDiv1{
                    background: black;
                    width: 200px;
                    height: 50px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-evenly;
                    padding: 0 3px;
                    // position: absolute;
                    // bottom: 25%;
                    // left: 7%;
        
                    @include respond-below(xs){
                        position: relative;
                        left: 0;
                        margin: 50px auto;
                    }
        
                    .buttonTitle{
                        color: white;
                    }
                    .gitHub{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
        .vector{
            width: 50%;
            filter : drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
            @include respond-above(xl){
                width: 40%;
            }
            @include respond-above(lg){
                width: 45%;
            }
            @include respond-below(xs){
                width: 100%;
            }
        }
    }
    .button{
        text-decoration: none;
        width: 200px;
        display: none;
        @include respond-below(xs){
            display: inline;
        }

        .buttonDiv2{
            background: black;
            width: 200px;
            height: 50px;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            padding: 0 3px;
            @include respond-below(xs){
                margin: 50px auto;
            }

            .buttonTitle{
                color: white;
            }
            .gitHub{
                width: 30px;
                height: 30px;
            }
        }
    }
    .footer{
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;

        @include respond-below(md){
            margin: 50px 0;
        }
        @include respond-below(xs){
            
            display: block;
            width: 80%;
            margin: 0 auto;
            font-size: 0.8rem;
        }

        .emoji{
            width: 20px;
        }
    }
}