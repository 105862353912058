@import '../../styles/_mixins';

.navbar{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px 5% 3px 5%;
    margin-top: 0;
    background: white;
    position: fixed;
    z-index: 10;

    @include respond-below(xs){
        justify-content: center;
        position: relative;
    }

    .logo{
        width: 60px;
        height: 60px;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
    }
    .navigation{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .links{
            text-decoration: none;
            color: black;
            margin: 0 20px;


            .navigate{
                font-size: 1.3rem;
            }
            .github{
                width: 50px;
                height: 50px;
            }
        }

        @include respond-below(xs){
            display: none;
        }
    }
}