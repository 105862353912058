
body{
  margin: 0;
}
.App {
  text-align: center;
  margin : 0 auto;
  font-family: 'Poppins', sans-serif;

}

